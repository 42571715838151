<template>
  <div style="width: 100%">
    <div class="card card-custom gutter-b" style="width: 100%">
      <div class="card-header border-0 py-5">
        <h3 class="card-title font-weight-bolder text-dark">
          <span
            >Average report by
            {{ this.isLessorReport ? 'lessors' : 'locations' }}:</span
          >
          <span>
            <span class="ml-5"
              ><span
                class="label label-lg label-inline label-light-primary font-weight-bold"
                >{{ range.start | formatDate }}</span
              ></span
            >
            <span
              ><span class="flex-shrink-0 my-2 mx-3"
                ><i class="la la-arrow-right"></i></span
            ></span>
            <span
              ><span
                class="label label-lg label-inline label-light-primary font-weight-bold"
                >{{ range.end | formatDate }}</span
              ></span
            >
          </span>
        </h3>
      </div>
      <div class="card-body pt-0">
        <VueApexCharts
          v-if="isReady"
          :options="options"
          :series="series"
        ></VueApexCharts>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { dateFilter } from 'vue-date-fns'
import { mapGetters } from 'vuex'

export default {
  name: 'PerLocationsTrafficRefactored',
  filters: {
    date: dateFilter,
  },
  components: {
    VueApexCharts,
  },
  props: {
    chartData: {
      type: Array,
    },
    range: {
      type: Object,
    },
    selectedLocations: {
      type: [Array, undefined],
    },
    selectedLessors: {
      type: [Array, undefined],
    },
  },
  data() {
    return {
      isLessorReport: !!this.selectedLessors,
      transactions: [],
      coinTransactions: [],
      smsTransactions: [],
      cardTransactions: [],
      loyaltyTransactions: [],
      locations: this.selectedLocations
        ? this.selectedLocations.reduce((acc, location) => {
            acc[location.title] = {
              coin: { total: 0 },
              sms: { total: 0 },
              card: { total: 0 },
              loyalty: { total: 0 },
            }
            return acc
          }, {})
        : this.selectedLessors.reduce((acc, lessor) => {
            acc[lessor.name] = {
              coin: { total: 0 },
              sms: { total: 0 },
              card: { total: 0 },
              loyalty: { total: 0 },
            }
            return acc
          }, {}),
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -2,
          offsetY:
            this.selectedLocations?.length || this.selectedLessors?.length,
          formatter(val) {
            return (
              val
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
            )
          },
        },
        xaxis: {
          categories:
            this.selectedLocations?.map((location) => location.title) ||
            this.selectedLessors?.map((location) => location.name),
          labels: {
            show: true,
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            formatter(val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
              )
            },
          },
        },
        grid: {
          show: true,
        },
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: 'PayCoin',
          data: [],
        },
        {
          name: 'PaySMS',
          data: [],
        },
        {
          name: 'PayCard',
          data: [],
        },
        {
          name: 'PayLoyalty',
          data: [],
        },
      ],
      isReady: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyModule']),
  },
  mounted() {
    const mq = window.matchMedia('(min-width: 480px)')

    if (!mq.matches) {
      this.options.chart.type = 'area'
      this.options.xaxis.labels.show = false
      this.options.yaxis.show = false
    }

    if (this.currentUserCompanyModule[0] === 'gaming')
      this.options.legend.show = true

    this.chartData.map((traffic) => {
      if (traffic.payment_type === 'PayCoin') {
        this.series[0].data = traffic.data
      } else if (traffic.payment_type === 'PaySMS') {
        this.series[1].data = traffic.data
      } else if (traffic.payment_type === 'PayCard') {
        this.series[2].data = traffic.data
      } else if (traffic.payment_type === 'PayLoyalty') {
        this.series[3].data = traffic.data
      }
    })

    setTimeout(() => {
      this.isReady = true
    }, 500)
  },
}
</script>
