<template>
  <ul class="menu-nav">
    <router-link
      custom
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">{{ $t('MAIN_MENU.DASHBOARD') }}</span>
        </a>
      </li>
    </router-link>

    <!-- LOYALTY -->
    <li
      v-role:any="'admin|super_admin'"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-active': hasActiveChildren([
          '/loyalty/owners/list',
          '/loyalty/owners/new',
          '/loyalty/owners/edit',
          '/loyalty/owners/transactions',
          '/loyalty/reports/card',
          '/loyalty/cards/list',
          '/loyalty/cards/new',
          '/loyalty/cards/edit',
          '/loyalty/cards/upload',
          '/loyalty/promo-boxes/list',
          '/loyalty/promo-boxes/new',
          '/loyalty/promo-boxes/edit',
          '/loyalty/user/list',
          '/loyalty/user/list/edit',
          '/loyalty/user/list/transactions',
          '/loyalty/top-up/list',
          '/loyalty/top-up/new',
          '/loyalty/general-report',
          '/loyalty/reports/owner',
          '/loyalty/reports/card',
          '/loyalty/reports/user',
          '/loyalty/promotions/all',
          '/loyalty/pos-owners/list',
          '/loyalty/pos-owners/new',
          '/loyalty/pos-owners/edit',
          '/loyalty/pos-locations/list',
          '/loyalty/pos-locations/edit',
          '/loyalty/pos-locations/new',
          '/loyalty/pos-workers/list',
          '/loyalty/pos-workers/new',
          '/loyalty/pos-workers/edit',
        ]),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <inline-svg src="media/svg/icons/Clothes/Briefcase.svg" />
        </span>
        <span class="menu-text">{{ $t('MAIN_MENU.LOYALTY') }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            v-if="
              currentUserRole === 'ROLE_SUPER_ADMIN' ||
              (currentUserRole === 'ROLE_ADMIN' &&
                currentUserCompanyOib === '05959244936')
            "
            custom
            to="/loyalty/owners/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.OWNERS') }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            custom
            v-if="
              currentUserRole === 'ROLE_SUPER_ADMIN' ||
              (currentUserRole === 'ROLE_ADMIN' &&
                currentUserCompanyOib === '05959244936')
            "
            to="/loyalty/owners/transactions"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">
                  {{ $t('MAIN_MENU.OWNER_TRANSACTIONS') }}
                </span>
              </a>
            </li>
          </router-link>
          <router-link
            custom
            v-if="
              currentUserRole === 'ROLE_SUPER_ADMIN' ||
              (currentUserRole === 'ROLE_ADMIN' &&
                currentUserCompanyOib === '05959244936')
            "
            to="/loyalty/cards/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">
                  {{ $t('MAIN_MENU.CARDS') }}
                </span>
              </a>
            </li>
          </router-link>
          <router-link
            custom
            v-if="this.currentUserPersonalInfo.email.includes('@arsfutura.co')"
            to="/loyalty/cards/upload"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">
                  {{ $t('MAIN_MENU.CARD_UPLOAD') }}
                </span>
              </a>
            </li>
          </router-link>
          <router-link
            custom
            v-if="
              currentUserRole === 'ROLE_SUPER_ADMIN' ||
              (currentUserRole === 'ROLE_ADMIN' &&
                currentUserCompanyOib === '05959244936')
            "
            to="/loyalty/top-up/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Top up </span>
              </a>
            </li>
          </router-link>
          <router-link
            custom
            v-if="
              currentUserRole === 'ROLE_SUPER_ADMIN' ||
              (currentUserRole === 'ROLE_ADMIN' &&
                currentUserCompanyOib === '05959244936')
            "
            to="/loyalty/promo-boxes/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> Promo boxes </span>
              </a>
            </li>
          </router-link>
          <router-link
            custom
            v-if="
              currentUserRole === 'ROLE_SUPER_ADMIN' ||
              (currentUserRole === 'ROLE_ADMIN' &&
                currentUserCompanyOib === '05959244936')
            "
            to="/loyalty/users/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> {{ $t('MAIN_MENU.USERS') }} </span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <!-- COMPANY -->
    <li v-role:any="'admin|worker'" class="menu-section">
      <h4 class="menu-text">{{ $t('MAIN_MENU.COMPANIES_AND_USERS') }}</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <li
      v-role:any="'admin|worker'"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-active': hasActiveChildren([
          '/companies/list',
          '/companies/new',
          '/companies/edit',
          '/companies/daily-revenue',
        ]),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <inline-svg src="media/svg/icons/Clothes/Briefcase.svg" />
        </span>
        <span class="menu-text">{{ $t('MAIN_MENU.COMPANIES') }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <!-- company.MY -->
          <router-link
            custom
            to="/companies/list-my"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="
              currentUserRole === 'ROLE_SUPER_ADMIN' ||
              currentUserRole === 'ROLE_ADMIN' ||
              currentUserRole === 'ROLE_WORKER' ||
              currentUserRole === 'ROLE_JUNIOR'
            "
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{
                  $t('MAIN_MENU.MY_COMPANIES')
                }}</span>
              </a>
            </li>
          </router-link>

          <!-- company.all -->
          <router-link
            v-if="currentUserRole === 'ROLE_SUPER_ADMIN'"
            custom
            to="/companies/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{
                  $t('MAIN_MENU.ALL_COMPANIES')
                }}</span>
              </a>
            </li>
          </router-link>

          <!-- company.dailyRevenue-->
          <router-link
            custom
            v-if="currentActiveModuleComponents?.company.dailyRevenue"
            to="/companies/daily-revenue"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{
                  $t('MAIN_MENU.DAILY_REVENUE')
                }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <!-- USERS -->
    <li
      v-role="'admin'"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-active': hasActiveChildren([
          '/users/list',
          '/users/new',
          '/users/edit',
        ]),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <inline-svg src="media/svg/icons/General/User.svg" />
        </span>
        <span class="menu-text">{{ $t('MAIN_MENU.USERS') }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <!-- USERS.ALL_USERS -->
          <router-link
            custom
            to="/users/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.ALL_USERS') }}</span>
              </a>
            </li>
          </router-link>

          <!--
          <router-link
            custom
            to="/users/new"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{
                  $t('MAIN_MENU.ADD_NEW_USER')
                }}</span>
              </a>
            </li>
          </router-link>
          -->
        </ul>
      </div>
    </li>

    <!-- DEVICES -->
    <li
      v-role:any="'admin|junior|worker|watcher|watcher_bills'"
      class="menu-section"
    >
      <h4 class="menu-text">{{ $t('MAIN_MENU.LOGGERS_AND_DEVICES') }}</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <!-- DEVICES.LOGGERS -->
    <!--
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
        'menu-item-active': hasActiveChildren([
          '/loggers/list',
          '/loggers/edit',
        ]),
      }"
    >


      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <inline-svg src="media/svg/icons/Media/Airplay.svg"/>
        </span>
        <span class="menu-text">{{ $t('MAIN_MENU.LOGGERS') }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
              custom
              to="/loggers/list"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.ALL_LOGGERS') }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>-->

    <!-- DEVICES:DEVICES -->
    <li
      v-role:any="'admin|junior|worker|watcher|watcher_bills|super_admin'"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-active': hasActiveChildren([
          '/devices/list',
          '/devices/new',
          '/devices/edit',
          '/devices/service-mode-history',
          '/devices/critical-history',
          '/devices/free-ride',
          '/devices/reset',
          '/devices/critical',
        ]),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <inline-svg src="media/svg/icons/Electric/Outlet.svg" />
        </span>
        <span class="menu-text">{{ $t('MAIN_MENU.DEVICES') }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <!-- DEVICES.ALL -->
          <router-link
            custom
            to="/devices/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.ALL_DEVICES') }}</span>
              </a>
            </li>
          </router-link>
          <!--
          <router-link
              custom
              to="/devices/new"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active',
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-bullet menu-bullet-dot"><span></span></i>
                  <span class="menu-text">{{
                    $t('MAIN_MENU.ADD_NEW_DEVICE')
                  }}</span>
                </a>
              </li>
            </router-link>
            -->

          <!--          <router-link
                      custom
                      to="/devices/critical-history"
                      v-slot="{ href, navigate, isActive, isExactActive }"
                    >
                      <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item"
                        :class="[
                          isActive && 'menu-item-active',
                          isExactActive && 'menu-item-active',
                        ]"
                      >
                        <a :href="href" class="menu-link" @click="navigate">
                          <i class="menu-bullet menu-bullet-dot"><span></span></i>
                          <span class="menu-text">{{
                            $t('MAIN_MENU.CRITICAL_HISTORY')
                          }}</span>
                        </a>
                      </li>
                    </router-link>
                    -->

          <!-- DEVICES.FREE_RIDE -->
          <router-link
            v-if="currentUserCompanyModule[0] === 'gaming'"
            v-role:any="'admin|junior|worker'"
            custom
            to="/devices/free-ride"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Send Free Ride</span>
              </a>
            </li>
          </router-link>

          <!-- DEVICES.RESET -->
          <router-link
            v-if="currentUserCompanyModule[0] === 'gaming'"
            v-role:any="'admin|junior|worker'"
            custom
            to="/devices/reset"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('GENERAL.RESET') }}</span>
              </a>
            </li>
          </router-link>

          <!-- DEVICES.CRITICAL -->
          <router-link
            v-if="
              this.currentUserRole === 'ROLE_SUPER_ADMIN' ||
              ((this.currentUserPersonalInfo.email.includes('@kidsdelux.hr') ||
                this.currentUserPersonalInfo.email.includes('@arsfutura.co')) &&
                this.currentUserRole === 'ROLE_ADMIN')
            "
            v-role:any="super_admin"
            custom
            to="/devices/critical"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{
                  $t('PAGES.CRITICAL_DEVICES')
                }}</span>
              </a>
            </li>
          </router-link>

          <!-- DEVICES.DISCHARGE_HISTORY -->
          <router-link
            v-if="
              currentUserRole === 'ROLE_SUPER_ADMIN' ||
              (this.currentUserPersonalInfo.email.includes('@kidsdelux.hr') &&
                currentUserRole === 'ROLE_ADMIN')
            "
            v-role:any="super_admin"
            custom
            to="/devices/discharge-history"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{
                  $t('DISCHARGE.DISCHARGE_HISTORY')
                }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <!-- DEVICES.MAINTENANCE -->
    <li
      v-if="
        currentUserCompanyModule[0] === 'gaming' &&
        currentActiveModuleComponents?.devices.maintenance
      "
      v-role:any="'admin|junior|worker'"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-active': hasActiveChildren([
          '/devices-maintenance/device',
          '/devices-maintenance/range',
        ]),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <inline-svg src="media/svg/icons/Tools/Tools.svg" />
        </span>
        <span class="menu-text">{{ $t('MAIN_MENU.MAINTENANCE') }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            custom
            to="/devices-maintenance/device"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{
                  $t('MAIN_MENU.MAINTENANCE_BY_DEVICE')
                }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            custom
            to="/devices-maintenance/range"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{
                  $t('MAIN_MENU.MAINTENANCE_IN_RANGE')
                }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <!-- DEVICES.MAP -->
    <router-link
      custom
      v-if="currentActiveModuleComponents?.devices.map"
      to="/devices/locations"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        v-role:any="'admin|junior|worker'"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <inline-svg src="media/svg/icons/Map/Marker2.svg" />
          </span>
          <span class="menu-text">{{ $t('MAIN_MENU.DEVICES_MAP') }}</span>
        </a>
      </li>
    </router-link>

    <!-- LOCATIONS -->
    <li v-role:any="'admin|worker'" class="menu-section">
      <h4 class="menu-text">{{ $t('MAIN_MENU.CITIES_LESSORS_AND_TAGS') }}</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <!-- LOCATION.CITIES -->
    <li
      v-if="currentUserRole === 'ROLE_SUPER_ADMIN'"
      v-role:any="'admin'"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-active': hasActiveChildren([
          '/cities/list',
          '/cities/new',
          '/cities/edit',
        ]),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <inline-svg src="media/svg/icons/Home/Building.svg" />
        </span>
        <span class="menu-text">{{ $t('MAIN_MENU.CITIES') }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            custom
            to="/cities/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.ALL_CITIES') }}</span>
              </a>
            </li>
          </router-link>
          &lt;!&ndash;
          <router-link
            custom
            to="/cities/new"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{
                  $t('MAIN_MENU.ADD_NEW_CITY')
                }}</span>
              </a>
            </li>
          </router-link>
          &ndash;&gt;
        </ul>
      </div>
    </li>

    <!-- LOCATION.LESSORS -->
    <li
      v-if="
        currentUserCompanyModule[0] === 'gaming' &&
        currentActiveModuleComponents?.location.lessors
      "
      v-role:any="'admin|worker'"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-active': hasActiveChildren([
          '/lessors/list',
          '/lessors/new',
          '/lessors/edit',
        ]),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <inline-svg src="media/svg/icons/Shopping/Wallet.svg" />
        </span>
        <span class="menu-text">{{ $t('MAIN_MENU.LESSORS') }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            custom
            to="/lessors/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.ALL_LESSORS') }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            v-if="
              currentUserRole === 'ROLE_SUPER_ADMIN' ||
              this.currentUserPersonalInfo.email.includes(
                'davor.botincan@kidsdelux.hr'
              )
            "
            custom
            to="/lessors/new"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{
                  $t('MAIN_MENU.ADD_NEW_LESSOR')
                }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <!-- LOCATION.LOCATIONS -->
    <li
      v-if="currentActiveModuleComponents?.location.locations"
      v-role:any="'admin|worker'"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-active': hasActiveChildren([
          '/locations/list',
          '/locations/new',
          '/locations/edit',
        ]),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <inline-svg src="media/svg/icons/Map/Position.svg" />
        </span>
        <span class="menu-text">{{ $t('MAIN_MENU.LOCATIONS') }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            custom
            to="/locations/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{
                  $t('MAIN_MENU.ALL_LOCATIONS')
                }}</span>
              </a>
            </li>
          </router-link>
          <!--
                    <router-link
                      custom
                      to="/locations/new"
                      v-slot="{ href, navigate, isActive, isExactActive }"
                    >
                      <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item"
                        :class="[
                          isActive && 'menu-item-active',
                          isExactActive && 'menu-item-active',
                        ]"
                      >
                        <a :href="href" class="menu-link" @click="navigate">
                          <i class="menu-bullet menu-bullet-dot"><span></span></i>
                          <span class="menu-text">{{
                            $t('MAIN_MENU.ADD_NEW_LOCATION')
                          }}</span>
                        </a>
                      </li>
                    </router-link>
                    -->
        </ul>
      </div>
    </li>

    <!-- LOCATION.TAGS -->
    <!--

        <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item menu-item-submenu"
            v-bind:class="{
            'menu-item-active': hasActiveChildren([
              '/tags/list',
              '/tags/new',
              '/tags/edit',
            ]),
          }"
        >
          <a href="#" class="menu-link menu-toggle">
            <span class="svg-icon menu-icon">
              <inline-svg src="media/svg/icons/Shopping/Price1.svg"/>
            </span>
            <span class="menu-text">{{ $t('MAIN_MENU.TAGS') }}</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu menu-submenu-classic menu-submenu-right">
            <ul class="menu-subnav">
              <router-link
                  custom
                  to="/tags/list"
                  v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">{{ $t('MAIN_MENU.ALL_TAGS') }}</span>
                  </a>
                </li>
              </router-link>
              <router-link
                  custom
                  to="/tags/new"
                  v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">{{ $t('MAIN_MENU.ADD_NEW_TAG') }}</span>
                  </a>
                </li>
              </router-link>
            </ul>
          </div>
        </li>
    -->

    <!-- REPORTS -->
    <li class="menu-section">
      <h4 class="menu-text">{{ $t('MAIN_MENU.REPORTS') }}</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      v-if="currentUserRole === 'ROLE_SUPER_ADMIN'"
      v-role:any="'admin|junior|worker'"
      custom
      to="/transactions-bills/comparison"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <inline-svg src="media/svg/icons/Design/Color-profile.svg" />
          </span>
          <span class="menu-text">{{
            $t('MAIN_MENU.TRANSACTIONS_BILLS_COMPARISON')
          }}</span>
        </a>
      </li>
    </router-link>

    <!-- REPORTS.DEPOSITS -->
    <li
      v-role:any="'admin|junior|worker'"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-active': hasActiveChildren(['/deposits/list']),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <inline-svg src="media/svg/icons/Shopping/Euro.svg" />
        </span>
        <span class="menu-text">{{ $t('MAIN_MENU.DEPOSITS') }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <!-- REPORTS.DEPOSITS.VIEW -->
          <router-link
            custom
            to="/deposits/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.VIEW') }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <!-- REPORTS.DETAILED_REVIEW -->
    <router-link
      v-if="currentActiveModuleComponents?.reports.detailedReview"
      v-role:any="'admin|junior|worker'"
      custom
      to="/detailed-review/list"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <inline-svg src="media/svg/icons/Design/Color-profile.svg" />
          </span>
          <span class="menu-text">{{ $t('MAIN_MENU.DETAILED_REVIEW') }}</span>
        </a>
      </li>
    </router-link>

    <!-- REPORTS.FINANCE -->
    <li
      v-role:any="'admin|watcher|worker|watcher_bills'"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-active': hasActiveChildren([
          '/device-report/device',
          '/device-report/device-new',
          '/location-report/location',
          '/location-report/company',
        ]),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <inline-svg src="media/svg/icons/Shopping/Chart-bar2.svg" />
        </span>
        <span class="menu-text">{{ $t('MAIN_MENU.FINANCE_REPORTS') }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <!-- REPORTS.FINANCE.DEVICE -->
          <router-link
            custom
            to="/finance-report/device"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{
                  $t('MAIN_MENU.FINANCE_REPORTS_BY_DEVICES')
                }}</span>
              </a>
            </li>
          </router-link>

          <!-- REPORTS.FINANCE.LOCATION -->
          <router-link
            v-role:any="'admin|worker'"
            custom
            to="/finance-report/location"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{
                  $t('MAIN_MENU.FINANCE_REPORTS_BY_LOCATIONS')
                }}</span>
              </a>
            </li>
          </router-link>

          <!-- REPORTS.FINANCE.LESSOR -->
          <router-link
            v-role:any="'admin|worker'"
            custom
            v-if="currentUserCompanyModule[0] === 'gaming'"
            to="/finance-report/lessor"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{
                  $t('MAIN_MENU.FINANCE_REPORTS_BY_LESSOR')
                }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            custom
            to="/finance-report/device-refactored"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="this.currentUserPersonalInfo.email.includes('@arsfutura.co')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> By Device Refactored </span>
              </a>
            </li>
          </router-link>
          <router-link
            custom
            to="/finance-report/location-refactored"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="this.currentUserPersonalInfo.email.includes('@arsfutura.co')"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> By Location Refactored </span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <!-- REPORTS.TOKEN -->
    <li
      v-role:any="'admin|watcher|worker|watcher_bills'"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-active': hasActiveChildren([
          '/token-report/device',
          '/token-report/location',
          '/token-report/lessor',
        ]),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <inline-svg src="media/svg/icons/Shopping/Chart-bar1.svg" />
        </span>
        <span class="menu-text">{{ $t('MAIN_MENU.TOKEN_REPORTS') }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <!-- REPORTS.TOKEN.DEVICE -->
          <router-link
            custom
            to="/token-report/device"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{
                  $t('MAIN_MENU.FINANCE_REPORTS_BY_DEVICES')
                }}</span>
              </a>
            </li>
          </router-link>

          <!-- REPORTS.TOKEN.LOCATION -->
          <router-link
            v-role:any="'admin|worker'"
            custom
            to="/token-report/location"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{
                  $t('MAIN_MENU.FINANCE_REPORTS_BY_LOCATIONS')
                }}</span>
              </a>
            </li>
          </router-link>

          <!-- REPORTS.TOKEN.LESSOR -->
          <router-link
            v-role:any="'admin|worker'"
            custom
            v-if="currentUserCompanyModule[0] === 'gaming'"
            to="/token-report/lessor"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{
                  $t('MAIN_MENU.FINANCE_REPORTS_BY_LESSOR')
                }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <!-- REPORTS.LOGISTIC -->
    <li
      v-role:any="'admin|worker'"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-active': hasActiveChildren([
          '/logistic-report/device',
          '/logistic-report/location',
          /* 
          '/logistic-report/lessor', */
        ]),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <inline-svg src="media/svg/icons/Shopping/Chart-bar3.svg" />
        </span>
        <span class="menu-text">{{ $t('MAIN_MENU.LOGISTIC_REPORTS') }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <!-- REPORTS.LOGISTIC.DEVICE -->
          <router-link
            custom
            to="/logistic-report/device"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{
                  $t('MAIN_MENU.FINANCE_REPORTS_BY_DEVICES')
                }}</span>
              </a>
            </li>
          </router-link>

          <!-- REPORTS.TOKEN.LOCATION -->
          <router-link
            v-role:any="'admin|worker'"
            custom
            to="/logistic-report/location"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{
                  $t('MAIN_MENU.FINANCE_REPORTS_BY_LOCATIONS')
                }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <!-- REPORTS.COMPARATIVE -->
    <li
      v-if="currentActiveModuleComponents?.reports.comparativeAnalysis"
      v-role:any="'admin|worker'"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-active': hasActiveChildren([
          '/comparative-analysis/device',
          '/comparative-analysis/location',
          '/comparative-analysis/lessor',
        ]),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <inline-svg src="media/svg/icons/Design/Difference.svg" />
        </span>
        <span class="menu-text">{{
          $t('MAIN_MENU.COMPARATIVE_ANALYSIS')
        }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <!-- REPORTS.COMPARATIVE.DEVICE -->
          <router-link
            custom
            to="/comparative-analysis/device"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{
                  $t('MAIN_MENU.COMPARATIVE_ANALYSIS_BY_DEVICES')
                }}</span>
              </a>
            </li>
          </router-link>

          <!-- REPORTS.COMPARATIVE.LOCATION -->
          <router-link
            custom
            to="/comparative-analysis/location"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{
                  $t('MAIN_MENU.COMPARATIVE_ANALYSIS_BY_LOCATIONS')
                }}</span>
              </a>
            </li>
          </router-link>

          <!-- REPORTS.COMPARATIVE.LESSOR -->
          <router-link
            custom
            v-if="currentUserCompanyModule[0] === 'gaming'"
            to="/comparative-analysis/lessor"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{
                  $t('MAIN_MENU.COMPARATIVE_ANALYSIS_BY_LESSOR')
                }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <router-link
      v-if="
        (this.currentUserPersonalInfo.email.includes('@kidsdelux.hr') &&
          this.currentUserRole === 'ROLE_ADMIN') ||
        currentUserPersonalInfo.email.includes('zeno.stok@kidsdelux.hr') ||
        this.currentUserPersonalInfo.email.includes('@arsfutura.co')
      "
      v-role:any="super_admin"
      custom
      to="/ebitda/list"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <inline-svg src="media/svg/icons/Design/Color-profile.svg" />
          </span>
          <span class="menu-text">EBITDA</span>
        </a>
      </li>
    </router-link>

    <!-- TRAFFIC -->
    <li v-role:any="'admin|worker'" class="menu-section">
      <h4 class="menu-text">{{ $t('MAIN_MENU.TRAFIC') }}</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <!-- TRAFFIC:TRANSACTION -->
    <router-link
      v-role:any="'admin|worker'"
      custom
      v-if="currentActiveModuleComponents?.traffic.transactions"
      to="/transactions/list"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <inline-svg src="media/svg/icons/Shopping/Barcode.svg" />
          </span>
          <span class="menu-text">{{ $t('MAIN_MENU.TRANSACTIONS') }}</span>
        </a>
      </li>
    </router-link>

    <!-- BILLS -->
    <li
      v-role:any="'admin|worker'"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-active': hasActiveChildren([
          '/bills/list',
          '/bills/filter',
          '/bills/new',
          '/bills/request',
          '/bills/unfiscalized',
        ]),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <inline-svg src="media/svg/icons/Shopping/Barcode-read.svg" />
        </span>
        <span class="menu-text">{{ $t('MAIN_MENU.BILLS') }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <!-- BILLS.ALL -->
          <router-link
            custom
            to="/bills/list"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.ALL_BILLS') }}</span>
              </a>
            </li>
          </router-link>

          <!-- BILLS.FILTER -->
          <router-link
            v-if="currentActiveModuleComponents?.bills.filterBills"
            custom
            to="/bills/filter"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Filter Bills</span>
              </a>
            </li>
          </router-link>

          <!-- BILLS.CREATE_PDF -->
          <router-link
            v-if="currentActiveModuleComponents?.bills.createNewBillPdf"
            custom
            to="/bills/new"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Create Bill PDF</span>
              </a>
            </li>
          </router-link>

          <!-- BILLS.CREATE_NEW -->
          <router-link
            custom
            to="/bills/request"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">{{ $t('MAIN_MENU.NEW_BILL') }}</span>
              </a>
            </li>
          </router-link>

          <!-- BILLS.UNFISCALIZED -->
          <router-link
            custom
            to="/bills/unfiscalized"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text">Unfiscalized</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <!-- GENERATE EXPORTS -->
    <!--
        <li
            v-if="currentActiveModuleComponents?.generateExports"
            class="menu-section"
        >
          <h4 class="menu-text">{{ $t('MAIN_MENU.EXPORT') }}</h4>
          <i class="menu-icon flaticon-more-v2"></i>
        </li>
        <router-link
            custom
            v-if="currentActiveModuleComponents?.generateExports"
            to="/exports/generate"
            v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <span class="svg-icon menu-icon">
                <inline-svg src="media/svg/icons/Files/Download.svg"/>
              </span>
              <span class="menu-text">{{ $t('MAIN_MENU.GENERATE') }}</span>
              <span class="menu-label">
                <span class="label label-rounded label-primary px-5 mr-3"
                >.xlsx</span
                >
                <span class="label label-rounded label-primary px-5">.pdf</span>
              </span>
            </a>
          </li>
        </router-link>
      -->
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Menu',
  computed: {
    ...mapGetters([
      'currentUserCompanyModule',
      'currentActiveModuleComponents',
      'currentUserCompanyOib',
      'currentUserRole',
      'currentUserPersonalInfo',
    ]),
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1
    },
  },
}
</script>
